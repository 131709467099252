import { useContext } from 'react'
import { storeContext } from '../store'
import { Redirect, Route, RouteProps } from 'react-router-dom'

const Loading = () => {
  return (
    <div className="modal cover">
      <div className="centered">
        Loading&nbsp;
        <div className="loadingio-spinner-dual-ring-pnjxq94gztn">
          <div className="ldio-h1rin2cgf">
            <div></div>
            <div>
              <div></div>
            </div>
          </div>
        </div>
        <p className="fadein">
          If this message does not disappear, try reloading the page.
        </p>
      </div>
    </div>
  )
}

const PrivateRoute = ({ children, ...props }: RouteProps) => {
  const { signedIn } = useContext(storeContext)
  return (
    <Route {...props}>
      {signedIn === undefined ? (
        <Loading />
      ) : signedIn ? (
        children
      ) : (
        <Redirect to="/" />
      )}
    </Route>
  )
}

const PublicRoute = ({ children, ...props }: RouteProps) => {
  const { signedIn } = useContext(storeContext)
  return (
    <Route {...props}>
      {signedIn === undefined ? (
        <Loading />
      ) : signedIn ? (
        <Redirect to="/dashboard" />
      ) : (
        children
      )}
    </Route>
  )
}

export { PrivateRoute, PublicRoute }
