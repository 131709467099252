import { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ActionType, storeContext } from '../store'

const baseURL = process.env.REACT_APP_SERVER_BASE_URL || ''

const Dashboard = () => {
  const history = useHistory()
  const { room, token, dispatch } = useContext(storeContext)
  const [roomCode, setRoomCode] = useState('')
  const [results, setResults] = useState<any>({})
  const [editing, setEditing] = useState(false)
  const [username, setUsername] = useState('')
  useEffect(() => {
    document.title = 'Dashboard'
  })
  useEffect(() => {
    ;(async () => {
      if (!editing) {
        const results = await (
          await fetch(`${baseURL}/info`, {
            headers: { authorization: `Bearer ${token}` },
          })
        ).json()
        setResults(results)
        if (results.username) {
          setUsername(results.username)
        }
      }
    })()
  }, [token, editing])
  useEffect(() => {
    if (room !== '') {
      history.replace('/ingame')
    }
  }, [room, history])
  return (
    <div id="dashboard">
      <h1>Dashboard</h1>
      <br />
      <div className="tagline">
        {results.username ? (
          editing ? (
            <>
              Welcome,{' '}
              <input
                type="text"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                placeholder="Player"
              />
            </>
          ) : (
            `Welcome, ${results.username}`
          )
        ) : (
          'Welcome'
        )}{' '}
        {results.username ? (
          <div
            className="button"
            onClick={async () => {
              if (editing) {
                await fetch(`${baseURL}/username`, {
                  body: JSON.stringify({
                    username,
                  }),
                  method: 'POST',
                  headers: {
                    authorization: `Bearer ${token}`,
                    'content-type': 'application/json',
                  },
                })
                setEditing(false)
              } else {
                setEditing(true)
              }
            }}
          >
            {editing ? 'Save' : 'Edit'}
          </div>
        ) : null}
      </div>
      <p>
        Rooms I've joined (all time):{' '}
        <span className="stat">{results.roomsJoined}</span>
      </p>
      <p>
        Number of active users: <span className="stat">{results.clients}</span>
      </p>
      <p>
        Number of active rooms: <span className="stat">{results.rooms}</span>
      </p>
      <br />
      <input
        type="text"
        value={roomCode}
        onChange={(event) => setRoomCode(event.target.value)}
        placeholder="Enter Room Code"
      />
      &nbsp;&nbsp;
      <div
        className="button"
        onClick={async () => {
          await fetch(`${baseURL}/room/${roomCode}/join`, {
            method: 'POST',
            headers: {
              authorization: `Bearer ${token}`,
            },
          })
        }}
      >
        Join Room
      </div>
      <br />
      <br />
      <div
        className="button"
        onClick={async () => {
          await fetch(`${baseURL}/room`, {
            method: 'POST',
            headers: {
              authorization: `Bearer ${token}`,
            },
          })
        }}
      >
        Create New Room
      </div>
      <br />
      <br />
      <br />
      <br />
      <div
        className="button red"
        onClick={() => {
          localStorage.removeItem('simcardSessionJWT')
          dispatch({ type: ActionType.SIGN_OUT })
        }}
      >
        Sign Out
      </div>
    </div>
  )
}

export default Dashboard
