import { useContext } from 'react'
import { storeContext } from '../store'

const Connecting = () => {
  const { connected, signedIn } = useContext(storeContext)
  return !connected && signedIn ? (
    <div className="modal cover">
      <div className="centered">
        Connecting&nbsp;
        <div className="loadingio-spinner-dual-ring-pnjxq94gztn">
          <div className="ldio-h1rin2cgf">
            <div></div>
            <div>
              <div></div>
            </div>
          </div>
        </div>
        <p className="fadein">
          If this message does not disappear, try reloading the page.
        </p>
      </div>
    </div>
  ) : null
}

export default Connecting
