import copy from 'copy-to-clipboard'
import {
  useEffect,
  useContext,
  useState,
  CSSProperties,
  useRef,
  useCallback,
} from 'react'
import { useHistory } from 'react-router-dom'
import { storeContext } from '../store'

const baseURL = process.env.REACT_APP_SERVER_BASE_URL || ''

const Ingame = () => {
  const history = useHistory()
  const { room, token, players, cards, theta, stacks, socket } =
    useContext(storeContext)
  const [copied, setCopied] = useState(false)
  const [dragging, setDragging] = useState<
    | { type: 'card'; card: number }
    | { type: 'stack'; id: string; single: boolean; card: number | undefined }
    | undefined
  >(undefined)
  const [focus, setFocus] = useState<string | undefined>(undefined)
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const down = useRef<undefined | (() => void)>()
  const test = useRef<HTMLDivElement | null>(null)
  const initial = useRef<{ x: number; y: number } | undefined>()
  const coords = useRef<{ x: number; y: number } | undefined>()
  useEffect(() => {
    if (room !== '') {
      document.title = `Room ${room}`
    }
  }, [room])
  useEffect(() => {
    if (room === '') {
      history.replace('/dashboard')
    }
  }, [room, history])
  const cartesianToPolar = useCallback(
    (x: number, y: number) => {
      const vmin10 =
        window.innerHeight < window.innerWidth
          ? window.innerHeight / 10
          : window.innerWidth / 10
      const centerX = window.innerWidth / 2
      const centerY = window.innerHeight / 2
      return {
        radius:
          Math.sqrt(Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2)) /
          vmin10,
        theta: -Math.atan2(x - centerX, y - centerY) + theta,
      }
    },
    [theta],
  )
  const handleCardAction = useCallback(
    (
      dragging:
        | { type: 'card'; card: number }
        | {
            type: 'stack'
            id: string
            single: boolean
            card: number | undefined
          },
      info: string,
      x: number,
      y: number,
    ) => {
      if (dragging.type === 'card') {
        if (info === 'board') {
          socket?.emit('playCardAsNewStack', {
            card: dragging.card,
            ...cartesianToPolar(x, y),
          })
        } else if (info === 'hand') {
          //no-op
        } else {
          socket?.emit('playCardOnStack', {
            stackID: info,
            card: dragging.card,
          })
        }
      } else {
        if (info === dragging.id) {
          if (dragging.single) {
            //no-op
          } else {
            socket?.emit('moveStack', {
              stackID: dragging.id,
              ...cartesianToPolar(x, y),
            })
          }
        } else if (info === 'board') {
          if (dragging.single) {
            socket?.emit('moveCardToNewStack', {
              stackID: dragging.id,
              ...cartesianToPolar(x, y),
            })
          } else {
            socket?.emit('moveStack', {
              stackID: dragging.id,
              ...cartesianToPolar(x, y),
            })
          }
        } else if (info === 'hand') {
          if (dragging.single) {
            socket?.emit('takeCard', { stackID: dragging.id })
          } else {
            socket?.emit('takeStack', { stackID: dragging.id })
          }
        } else {
          if (dragging.single) {
            socket?.emit('moveCardToStack', {
              oldStackID: dragging.id,
              newStackID: info,
            })
          } else {
            socket?.emit('moveStackOntoStack', {
              oldStackID: dragging.id,
              newStackID: info,
            })
          }
        }
      }
    },
    [socket, cartesianToPolar],
  )
  const mouseup = useCallback(
    (event: MouseEvent) => {
      if (dragging) {
        setTimeout(() => {
          const element = document.elementFromPoint(
            event.clientX,
            event.clientY,
          )
          if (element) {
            const info = element.getAttribute('data-info')
            if (info) {
              handleCardAction(dragging, info, event.clientX, event.clientY)
            }
          }
        }, 0)
      }
      setDragging(undefined)
      initial.current = undefined
      down.current = undefined
    },
    [dragging, handleCardAction],
  )
  const touchend = useCallback(
    (event: TouchEvent) => {
      if (dragging) {
        setTimeout(() => {
          if (coords.current) {
            const element = document.elementFromPoint(
              coords.current.x,
              coords.current.y,
            )
            if (element) {
              const info = element.getAttribute('data-info')
              if (info) {
                handleCardAction(
                  dragging,
                  info,
                  coords.current.x,
                  coords.current.y,
                )
              }
            }
          }
        }, 0)
      }
      setDragging(undefined)
      initial.current = undefined
      down.current = undefined
    },
    [dragging, handleCardAction],
  )
  useEffect(() => {
    const mousemove = (event: MouseEvent) => {
      setX(event.clientX)
      setY(event.clientY)
      if (
        down.current &&
        initial.current &&
        Math.sqrt(
          Math.pow(event.clientX - initial.current.x, 2) +
            Math.pow(event.clientY - initial.current.y, 2),
        ) > 10
      ) {
        setFocus(undefined)
        down.current()
      }
    }
    const click = (event: MouseEvent) => {
      setFocus(undefined)
    }

    const touchmove = (event: TouchEvent) => {
      setX(event.touches[0].clientX)
      setY(event.touches[0].clientY)
      coords.current = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      }
      if (down.current) {
        if (
          initial.current
            ? Math.sqrt(
                Math.pow(event.touches[0].clientX - initial.current.x, 2) +
                  Math.pow(event.touches[0].clientY - initial.current.y, 2),
              ) > 10
            : (window.innerHeight - event.touches[0].clientY) /
                window.innerHeight >
              0.1
        ) {
          setFocus(undefined)
          down.current()
        }
      }
    }
    const contextmenu = (event: MouseEvent) => {
      event.preventDefault()
    }

    document.addEventListener('mousemove', mousemove)
    document.addEventListener('click', click)
    document.addEventListener('mouseup', mouseup)
    document.addEventListener('touchend', touchend)
    document.addEventListener('touchmove', touchmove)
    document.addEventListener('contextmenu', contextmenu)

    return () => {
      document.removeEventListener('mousemove', mousemove)
      document.removeEventListener('click', click)
      document.removeEventListener('mouseup', mouseup)
      document.removeEventListener('touchend', touchend)
      document.removeEventListener('touchmove', touchmove)
      document.removeEventListener('contextmenu', contextmenu)
    }
  }, [mouseup, touchend])
  return (
    <div id="ingame">
      <div id="board" data-info="board">
        {players.map(({ id, theta: playerTheta, username }) => {
          const cosine = Math.cos(playerTheta - theta)
          const sine = Math.sin(playerTheta - theta)
          return (
            <div
              className={
                sine > 0.001
                  ? 'player left'
                  : sine < -0.001
                  ? 'player right'
                  : 'player'
              }
              key={id}
              style={
                {
                  '--cosine': cosine,
                  '--sine': sine,
                } as CSSProperties
              }
            >
              {username}
            </div>
          )
        })}
        {stacks.map(({ id, top, theta: cardTheta, radius, single }) => {
          const cosine = Math.cos(cardTheta - theta)
          const sine = Math.sin(cardTheta - theta)
          return (
            <div
              className={
                dragging &&
                dragging.type === 'stack' &&
                dragging.id === id &&
                dragging.single === false
                  ? 'card hidden'
                  : focus === id
                  ? 'card focus'
                  : 'card'
              }
              data-info={id}
              key={id}
              style={
                {
                  '--cosine': cosine,
                  '--sine': sine,
                  '--radius': radius,
                } as CSSProperties
              }
              // onClick={() => {
              //   socket?.emit('takeStack', { stackID: id })
              // }}
            >
              <div className="overlay">
                <div
                  className={top === undefined ? 'flip show' : 'flip hide'}
                  onClick={(event) => {
                    event.stopPropagation()
                    setDragging(undefined)
                    initial.current = undefined
                    down.current = undefined
                    socket?.emit('toggleVisible', { stackID: id })
                  }}
                ></div>
                {!single && (
                  <img
                    draggable="false"
                    src={
                      top !== undefined
                        ? `${process.env.PUBLIC_URL}/media/${top}.svg`
                        : `${process.env.PUBLIC_URL}/media/back.svg`
                    }
                    onMouseDown={(event) => {
                      setX(event.clientX)
                      setY(event.clientY)
                      initial.current = { x: event.clientX, y: event.clientY }
                      down.current = () => {
                        down.current = undefined
                        setDragging({
                          type: 'stack',
                          id,
                          single: true,
                          card: top,
                        })
                      }
                    }}
                    onTouchStart={(event) => {
                      setX(event.touches[0].clientX)
                      setY(event.touches[0].clientY)
                      down.current = () => {
                        down.current = undefined
                        setDragging({
                          type: 'stack',
                          id,
                          single: true,
                          card: top,
                        })
                      }
                    }}
                    onClick={(event) => {
                      event.stopPropagation()
                      setFocus((focus) => (focus === id ? undefined : id))
                      setDragging(undefined)
                      initial.current = undefined
                      down.current = undefined
                    }}
                    alt=""
                    onDragStart={(e) => {
                      e.preventDefault()
                    }}
                  />
                )}
              </div>
              <img
                draggable="false"
                data-info={id}
                src={
                  dragging &&
                  dragging.type === 'stack' &&
                  dragging.id === id &&
                  dragging.single === true
                    ? `${process.env.PUBLIC_URL}/media/back.svg`
                    : focus === id && !single
                    ? `${process.env.PUBLIC_URL}/media/back.svg`
                    : top !== undefined
                    ? `${process.env.PUBLIC_URL}/media/${top}.svg`
                    : `${process.env.PUBLIC_URL}/media/back.svg`
                }
                onMouseDown={(event) => {
                  setX(event.clientX)
                  setY(event.clientY)
                  initial.current = { x: event.clientX, y: event.clientY }
                  down.current = () => {
                    down.current = undefined
                    setDragging({
                      type: 'stack',
                      id,
                      single: false,
                      card: top,
                    })
                  }
                }}
                onTouchStart={(event) => {
                  setX(event.touches[0].clientX)
                  setY(event.touches[0].clientY)
                  down.current = () => {
                    down.current = undefined
                    setDragging({
                      type: 'stack',
                      id,
                      single: false,
                      card: top,
                    })
                  }
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  setFocus((focus) => (focus === id ? undefined : id))
                  setDragging(undefined)
                  initial.current = undefined
                  down.current = undefined
                }}
                alt=""
                onDragStart={(e) => {
                  e.preventDefault()
                }}
              />
            </div>
          )
        })}
      </div>
      <div
        id="hand"
        data-info="hand"
        ref={test}
        onScroll={() => {
          down.current = undefined
        }}
        onWheel={(event) => {
          if (test.current && Math.abs(event.deltaY) > Math.abs(event.deltaX)) {
            test.current.scrollLeft += event.deltaY
          }
        }}
      >
        {cards.map((card) => (
          <div
            className={
              dragging && dragging.type === 'card' && dragging.card === card
                ? 'card hidden'
                : 'card'
            }
            data-info="hand"
            key={card}
            onMouseDown={(event) => {
              setX(event.clientX)
              setY(event.clientY)
              setDragging({ type: 'card', card })
            }}
            onTouchStart={(event) => {
              setX(event.touches[0].clientX)
              setY(event.touches[0].clientY)
              down.current = () => {
                down.current = undefined
                setDragging({ type: 'card', card })
              }
            }}
          >
            <img
              data-info="hand"
              draggable="false"
              src={`${process.env.PUBLIC_URL}/media/${card}.svg`}
              alt=""
              onDragStart={(e) => {
                e.preventDefault()
              }}
            />
          </div>
        ))}
      </div>

      {dragging && (
        <div
          className="card dragging"
          style={
            {
              '--y': y,
              '--x': x,
            } as CSSProperties
          }
        >
          <img
            draggable="false"
            src={
              dragging
                ? `${process.env.PUBLIC_URL}/media/${
                    dragging.card !== undefined ? dragging.card : 'back'
                  }.svg`
                : undefined
            }
            alt=""
            onDragStart={(e) => {
              e.preventDefault()
            }}
          />
        </div>
      )}
      <div id="info">
        <div
          className="code"
          onClick={() => {
            if (copy(room)) {
              setCopied(true)
            }
          }}
        >
          Room: {room}&nbsp;&nbsp;
          <div className={copied ? 'button green' : 'button'}>
            {copied ? 'Copied!' : 'Copy'}
          </div>
        </div>
        <br />

        <br />
        <div
          className="button red"
          onClick={async () => {
            await fetch(`${baseURL}/room/leave`, {
              method: 'POST',
              headers: {
                authorization: `Bearer ${token}`,
              },
            })
          }}
        >
          Leave Room
        </div>
      </div>
    </div>
  )
}

export default Ingame
