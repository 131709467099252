import { useEffect } from 'react'

const Login = () => {
  useEffect(() => {
    document.title = 'Sign In'
  })
  return (
    <div className="modal">
      <div className="centered">
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>simcard</h1>
        <p>the universal card game simulator</p>
        <br />
        <div
          ref={(node) => {
            if (node) {
              window.google.accounts.id.renderButton(node, { shape: 'pill' })
            }
          }}
        ></div>
        <br />
        <br />
        <br />
        <br />
        <p className="faded">
          created by <a href="https://trevorjex.com">trevor jex</a>. copyright
          &copy; {new Date().getFullYear()}. all rights reserved
        </p>
      </div>
    </div>
  )
}

export default Login
