import { Socket } from 'socket.io-client'

import { createContext, Dispatch } from 'react'

export type Store = {
  socket?: Socket
  token?: string
  signedIn?: boolean
  connected: boolean
  room: string
  theta: number
  cards: Array<number>
  players: Array<{ id: string; username: string; theta: number }>
  stacks: Array<{
    id: string
    top: number | undefined
    single: boolean
    theta: number
    radius: number
  }>
}

export type Context = Store & {
  dispatch: Dispatch<Action>
}

export enum ActionType {
  SIGN_IN,
  SIGN_OUT,
  CLEAR_SIGNED_IN,
  SET_SOCKET,
  CLEAR_SOCKET,
  SET_CONNECTED,
  SET_DISCONNECTED,
  JOIN_ROOM,
  LEAVE_ROOM,
  UPDATE_PLAYERS,
  UPDATE_STACKS,
  UPDATE_HAND,
}

export type Action =
  | signIn
  | signOut
  | clearSignedIn
  | setSocket
  | clearSocket
  | setConnected
  | setDisconnected
  | joinRoom
  | leaveRoom
  | updatePlayers
  | updateStacks
  | updateHand

type signIn = {
  type: ActionType.SIGN_IN
  payload: string
}

type signOut = {
  type: ActionType.SIGN_OUT
}

type clearSignedIn = {
  type: ActionType.CLEAR_SIGNED_IN
}

type setSocket = {
  type: ActionType.SET_SOCKET
  payload: Socket
}

type clearSocket = {
  type: ActionType.CLEAR_SOCKET
}

type setConnected = {
  type: ActionType.SET_CONNECTED
}

type setDisconnected = {
  type: ActionType.SET_DISCONNECTED
}

type joinRoom = {
  type: ActionType.JOIN_ROOM
  payload: {
    roomCode: string
    stacks: Array<{
      id: string
      top: number | undefined
      single: boolean
      theta: number
      radius: number
    }>
    players: Array<{ id: string; username: string; theta: number }>
    theta: number
    cards: Array<number>
  }
}

type leaveRoom = {
  type: ActionType.LEAVE_ROOM
}

type updatePlayers = {
  type: ActionType.UPDATE_PLAYERS
  payload: {
    players: Array<{ id: string; username: string; theta: number }>
    theta: number
  }
}

type updateStacks = {
  type: ActionType.UPDATE_STACKS
  payload: {
    updateTop: Array<{ id: string; top: number | undefined; single?: boolean }>
    updatePosition: Array<{ id: string; radius: number; theta: number }>
    delete: Array<{ id: string }>
    new: Array<{
      id: string
      radius: number
      theta: number
      top: number | undefined
      single: boolean
    }>
  }
}

type updateHand = {
  type: ActionType.UPDATE_HAND
  payload: {
    add: Array<number>
    remove: Array<number>
  }
}

export const reducer = (store: Store, action: Action): Store => {
  switch (action.type) {
    case ActionType.SIGN_IN:
      return { ...store, signedIn: true, token: action.payload }
    case ActionType.SIGN_OUT:
      return { ...store, signedIn: false }
    case ActionType.CLEAR_SIGNED_IN:
      return { ...store, signedIn: undefined }
    case ActionType.SET_SOCKET:
      return { ...store, socket: action.payload }
    case ActionType.CLEAR_SOCKET:
      return { ...store, socket: undefined }
    case ActionType.SET_CONNECTED:
      return { ...store, connected: true }
    case ActionType.SET_DISCONNECTED:
      return { ...store, connected: false }
    case ActionType.JOIN_ROOM:
      return {
        ...store,
        room: action.payload.roomCode,
        theta: action.payload.theta,
        cards: action.payload.cards,
        players: action.payload.players,
        stacks: action.payload.stacks,
      }
    case ActionType.LEAVE_ROOM:
      return {
        ...store,
        room: '',
        theta: 0,
        cards: [],
        players: [],
        stacks: [],
      }
    case ActionType.UPDATE_PLAYERS:
      return {
        ...store,
        players: action.payload.players,
        theta: action.payload.theta,
      }
    case ActionType.UPDATE_STACKS:
      return {
        ...store,
        stacks: store.stacks
          .filter((stack) => {
            const update = action.payload.delete.find(
              (update) => update.id === stack.id,
            )
            if (update) {
              return false
            }
            return true
          })
          .map((stack) => {
            const updateTop = action.payload.updateTop.find(
              (update) => update.id === stack.id,
            )
            if (updateTop) {
              return {
                ...stack,
                top: updateTop.top,
                single:
                  updateTop.single !== undefined
                    ? updateTop.single
                    : stack.single,
              }
            }
            return stack
          })
          .map((stack) => {
            const updatePosition = action.payload.updatePosition.find(
              (update) => update.id === stack.id,
            )
            if (updatePosition) {
              return {
                ...stack,
                theta: updatePosition.theta,
                radius: updatePosition.radius,
              }
            }
            return stack
          })
          .concat(action.payload.new),
      }
    case ActionType.UPDATE_HAND:
      return {
        ...store,
        cards: store.cards
          .filter((card) => !action.payload.remove.includes(card))
          .concat(action.payload.add),
      }
    default:
      return store
  }
}
export const initialStore: Store = {
  connected: false,
  room: '',
  theta: 0,
  cards: [],
  players: [],
  stacks: [],
}
export const storeContext = createContext<Context>({
  ...initialStore,
  dispatch: () => {},
})
