import { useEffect, useReducer, useRef, useCallback } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Manager } from 'socket.io-client'
import { PrivateRoute, PublicRoute } from './views/routes'
import { reducer, initialStore, storeContext, ActionType } from './store'
import { Login, Dashboard, Ingame, Connecting } from './views'
import { CredentialResponse } from 'google-one-tap'

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''
const baseURL = process.env.REACT_APP_SERVER_BASE_URL || ''

const App = () => {
  const [store, dispatch] = useReducer(reducer, initialStore)
  const token = useRef<string | undefined>(undefined)
  useEffect(() => {
    const callback = async (credentialResponse: CredentialResponse) => {
      console.log(credentialResponse)

      dispatch({
        type: ActionType.CLEAR_SIGNED_IN,
      })
      const response = await (
        await fetch(`${baseURL}/auth`, {
          method: 'GET',
          headers: {
            authorization: `Bearer ${credentialResponse.credential}`,
          },
        })
      ).json()
      token.current = response.jwt
      localStorage.setItem('simcardSessionJWT', response.jwt)
      dispatch({
        type: ActionType.SIGN_IN,
        payload: response.jwt,
      })
    }
    const savedToken = localStorage.getItem('simcardSessionJWT')

    window.google.accounts.id.initialize({
      client_id: clientId,
      callback,
      auto_select: true,
    })
    if (savedToken) {
      dispatch({ type: ActionType.SIGN_IN, payload: savedToken })
    } else {
      dispatch({ type: ActionType.SIGN_OUT })
      window.google.accounts.id.prompt()
    }
  }, [])
  useEffect(() => {
    token.current = store.token
  }, [store.token])
  const connectSocket = useCallback(() => {
    if (store.signedIn) {
      const manager = new Manager(baseURL)
      const socket = manager.socket('/', {
        auth: {
          jwt: token.current,
        },
      })
      socket.on('connect_error', (error: any) => {
        if (error && error.message && error.message === 'TOKEN_EXPIRED') {
          dispatch({ type: ActionType.SIGN_OUT })
          localStorage.removeItem('simcardSessionJWT')
        } else {
          dispatch({ type: ActionType.SIGN_OUT })
          localStorage.removeItem('simcardSessionJWT')
        }
      })
      socket.on('connect', () => {
        dispatch({ type: ActionType.SET_CONNECTED })
      })
      socket.on('disconnect', (reason: string) => {
        dispatch({ type: ActionType.SET_DISCONNECTED })
      })
      socket.on(
        'joinRoom',
        (info: {
          roomCode: string
          stacks: Array<{
            id: string
            top: number | undefined
            single: boolean
            theta: number
            radius: number
          }>
          players: Array<{ id: string; username: string; theta: number }>
          theta: number
          cards: Array<number>
        }) => {
          dispatch({ type: ActionType.JOIN_ROOM, payload: info })
        },
      )
      socket.on('leaveRoom', () => {
        dispatch({ type: ActionType.LEAVE_ROOM })
      })
      socket.on(
        'updatePlayers',
        (info: {
          players: Array<{ id: string; username: string; theta: number }>
          theta: number
        }) => {
          dispatch({ type: ActionType.UPDATE_PLAYERS, payload: info })
        },
      )
      socket.on(
        'updateStacks',
        (info: {
          updateTop: Array<{
            id: string
            top: number | undefined
            single?: boolean
          }>
          updatePosition: Array<{ id: string; radius: number; theta: number }>
          delete: Array<{ id: string }>
          new: Array<{
            id: string
            radius: number
            theta: number
            top: number | undefined
            single: boolean
          }>
        }) => {
          dispatch({ type: ActionType.UPDATE_STACKS, payload: info })
        },
      )
      socket.on(
        'updateHand',
        (info: { add: Array<number>; remove: Array<number> }) => {
          dispatch({ type: ActionType.UPDATE_HAND, payload: info })
        },
      )
      ;(window as any).socket = socket
      dispatch({ type: ActionType.SET_SOCKET, payload: socket })
      return () => {
        dispatch({ type: ActionType.CLEAR_SOCKET })
        socket.disconnect()
      }
    }
  }, [store.signedIn])
  useEffect(() => {
    return connectSocket()
  }, [connectSocket])

  return (
    <storeContext.Provider value={{ ...store, dispatch }}>
      <BrowserRouter>
        <PublicRoute exact path="/">
          <Login />
        </PublicRoute>
        <PrivateRoute exact path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute exact path="/ingame">
          <Ingame />
        </PrivateRoute>
      </BrowserRouter>
      <Connecting />
    </storeContext.Provider>
  )
}

export default App
